@use './c.scss';

.popup {
	position: fixed;
	pointer-events: none;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;

	&__content {
		position: absolute;
		bottom: 0;
		right: 0;
		margin: 15px;
		padding: 15px;
		background-color: white;
		border: solid 1px var(--grafit);
		border-radius: 15px;
	}
}

.submit-button {
	margin: 5px;
	padding: 5px;
	border-radius: 30px;
	color: white;
	background-color: var(--grafit);
	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: center;
}

.rounded-button {
	margin: 5px;
	padding: 5px;
	max-height: 20px;
	// max-width: 200px;
	// border-radius: 30px;
	color: white;
	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: center;
	cursor: pointer;
	&:hover {
		filter: brightness(85%);
	}
	&:active {
		filter: brightness(80%);
	}
	&-about {
		width: 180px;
		padding: 15px;
		font-size: small;
	}
	&_orange {
		background-color: var(--terrakot);
	}
}

.row-with-picture {
	margin-top: 30px;
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: center;
	gap: 20px;
}
.row-with-picture > img {
	max-height: 50px;
}

.search-input {
	display: flex;
	flex-direction: row;
	gap: 3px;
	& :focus {
		border-bottom: 1px solid var(--gray);
	}
	& input {
		font-family: inherit;
		transition: all ease 0.2s;
		max-width: 0;
		border: none;
		margin: 0;
		padding: 0;
		&:focus {
			outline: none;
		}
	}
	&.active {
		& input {
			max-width: 200px;
			visibility: visible;
		}
	}
}

.textarea {
	padding: 5px;
	font-family: inherit;
	height: auto;
	line-height: 24px;
	resize: vertical;
	border: none;
	&:focus {
		outline: none;
		border-bottom: 1px solid var(--terrakot);
	}
}

.icon {
	width: 100%;
	display: flex;
	flex-direction: row;
	justify-content: flex-start;
	gap: 10px;
}

.curtain {
	@media screen and (min-width: 1200px) {
		position: relative;
		max-height: 600px;
		width: 100%;
		transition: all ease 0.3s;

		&-content {
			display: grid;
			grid-template-columns: 50% 50%;
			grid-template-rows: 100%;
			height: 600px;
			position: relative;
			transition: all ease 0.3s;

			&_collapsed {
				opacity: 0;
				height: 0;
			}

			&-left {
				z-index: 2;
				position: relative;

				&__tribal {
					z-index: 5;
					top: 0;
					left: 3px;
					height: 597px;
					bottom: 0;
					position: absolute;
					animation: slide 0.6s forwards;
					animation-timing-function: ease-in-out;
					// transform: rotate(90deg);
				}

				&__trapezoid {
					height: 100%;
					// width: calc(100% + 80px);
					background-color: var(--grafit);
					animation: slide 0.6s forwards;
					animation-delay: 0.3s;
					animation-timing-function: ease-in-out;
				}

				& > span {
					position: absolute;
					width: 400px;
					top: 50%;
					left: 65%;
					transform: translate(-50%, -50%);
					z-index: 3;
					color: white;

					& > h1 {
						font-size: 50px;
					}

					& > p {
						font-size: 20px;
						font-weight: normal;
					}
				}

				& > h1 {
					font-size: 50px;
					position: absolute;
					top: 50%;
					left: 50%;
					transform: translate(-50%, -50%);
					z-index: 3;
					color: white;
				}
			}

			&-right {
				background-size: cover;
				background-position: top;
				animation: slideRight 0.6s forwards;
				animation-delay: 0.3s;
				animation-timing-function: ease-in-out;
			}
		}
	}

    @media screen and (max-width: 1199px) {
		position: relative;
		width: 100%;
		transition: all ease 0.3s;

		&-content {
			display: grid;
			// grid-template-columns: 50% 50%;
			grid-template-rows: auto auto;
			height: 500px;
			position: relative;
			transition: all ease 0.3s;

			&_collapsed {
				opacity: 0;
				height: 0;
			}

			&-left {
				z-index: 2;
				position: relative;

				&__tribal {
					z-index: 5;
					top: 0;
					left: 0px;
					height: 250px;
					bottom: 0;
					position: absolute;
					animation: slide 0.6s forwards;
					animation-timing-function: ease-in-out;
					// transform: rotate(90deg);
				}

				&__trapezoid {
					height: 100%;
					// width: calc(100% + 80px);
					background-color: var(--grafit);
					animation: slide 0.6s forwards;
					animation-delay: 0.3s;
					animation-timing-function: ease-in-out;
				}

				& > span {
					top: 50%;
					transform: translateY(-50%);
					position: absolute;
					left: 55px;
					right: 10px;
					z-index: 3;

					color: white;

					& > p {
						font-weight: normal;
					}
				}

				& > h1 {
					font-size: 2rem;
					position: absolute;
					top: 50%;
					transform: translateY(-50%);
					left: 25%;
					z-index: 3;
					color: white;
				}
			}

			&-right {
				background-size: cover;
				background-position: top;
				animation: slideRight 0.6s forwards;
				animation-delay: 0.3s;
				animation-timing-function: ease-in-out;
			}
		}
	}

	&-button {
		z-index: 5;
		position: absolute;
		bottom: 0;
		left: 50%;
		transform: translate(-50%, 79%);
		width: 0;
		height: 0;
		border-left: 40px solid transparent;
		border-right: 40px solid transparent;
		border-top: 50px solid var(--yellow);
		border-radius: 50%;
		transition: ease all 0.3s;
		display: relative;
		cursor: pointer;

		& > i {
			left: 50%;
			transform: translate(-50%, -250%);
			position: absolute;
		}

		&:hover {
			color: var(--white);
			box-shadow: 0 0 0 5px rgba($color: white, $alpha: 0.1),
				0 0 0 10px rgba($color: white, $alpha: 0.2),
				0 0 0 15px rgba($color: white, $alpha: 0.4);
		}

		&:active {
			filter: brightness(80%);
		}

		& i {
			font-size: 18px;
		}
	}
}

@keyframes slide {
	0% {
		transform: translateX(-1000px);
	}
	100% {
		transform: translateX(-0px);
	}
}

@keyframes slideRight {
	0% {
		transform: translateX(1000px);
	}
	100% {
		transform: translateX(0px);
	}
}

// @keyframes slide {
//     0% { transform: skewX(0deg) translateX(-1000px); }
//     100% { transform: skewX(-7deg) translateX(-40px); }
// }

@keyframes fadeIn {
	0% {
		opacity: 0;
	}
	100% {
		opacity: 1;
	}
}
