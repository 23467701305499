$width: 140px;
$height: 140px;

.career-hh {
    width: 100%;
    margin: 35px 0;
    background-color: var(--dark-gray);
    padding: 20px 0;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    z-index: 5;
    padding-bottom: 40px;

    &__buttons {
        margin-top: 35px;
        position: relative;
        display: flex;
        flex-direction: row;
        justify-content: center;
        z-index: 1;
        color: var(--grafit);

        @media screen and (max-width: 700px) {
            gap: 15px
        }

        @media screen and (max-width: 475px) {
            flex-direction: column;
        }
    }

    &__button {
        border-radius: 10px;
        background-color: var(--darker-gray);
        color: var(--grafit);
        width: $width;
        height: $height;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        text-align: center;
        cursor: pointer;
        transition: all ease 0.3s;

        &:hover a {
            color: white !important;
        }

        &:hover {
            border-radius: 50%;
            background-color: var(--terrakot);
            color: white !important;
        }
    }

    &__or {
        color: var(--terrakot);
        font-size: 32px;
        width: $width + 80;
        height: $height;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        text-align: center;
    }

    &__hh {
        border-radius: 10px;
        background-color: var(--darker-gray);
        position: relative;
        color: var(--grafit);
        width: $width;
        height: $height;
        padding: 0px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        text-align: center;
        cursor: pointer;
        transition: all ease 0.3s;

        &::before {
            line-height: calc($height - 20px);
            font-size: 80px;
            font-family: Arial, Helvetica, sans-serif;
            position: absolute;
            top: 10px;
            left: 0;
            color: white;
            content: 'hh';
            width: calc($width - 20px);
            height: calc($height - 20px);
            background-color: red;
            border-radius: 50%;
            z-index: -1;
            transition: all ease 0.5s;
        }

        &:hover {
            border-radius: 50%;
            background-color: var(--grafit);
            color: white !important;
            &::before {
                transform: translateX(-80px) rotate(-30deg);
            }
        }
    }

    &__mail {
        border-radius: 10px;
        background-color: var(--darker-gray);
        position: relative;
        color: var(--grafit);
        width: $width;
        height: $height;
        padding: 0px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        text-align: center;
        cursor: pointer;
        transition: all ease 0.3s;

        &::before {
            font-family: Arial, Helvetica, sans-serif;
            position: absolute;
            padding: 10px;
            background: url('/icon_mail.svg') no-repeat 20px 25px;
            background-size: 100px;
            display: inline-block;
            content: '';
            width: calc($width - 20px);
            height: calc($height - 20px);
            // background-color: white;
            border-radius: 50%;
            z-index: -1;
            transition: all ease 0.5s;

        }

        &:hover {
            border-radius: 50%;
            background-color: var(--green);
            color: white !important;
            &::before {
                transform: translateX(80px) rotate(30deg);
            }
        }
    }
}

.career-contacts {
    margin: 40px 0px;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-auto-rows: minmax(20px, auto);
    gap: 10px 100px;

    @media screen and (max-width: 700px) {
        grid-template-columns: repeat(1, 1fr);
        gap: 70px;
    }

    &__contact {

        & img {
            cursor: pointer;
            float: left;
            height: 125px;
            margin: -35px 0px;
        }

        &:hover a {
            color: var(--terrakot);
        }

        & div {
            height: 50px;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;

            & a, span {
                color: var(--grafit);
                width: 250px;
                align-self: start;
                margin-left: 10px;

                @media screen and (max-width: 700px) {
                    width: unset;
                }
            }

            & span {
                margin-bottom: 10px;
            }
        }
    }
}