@use './c.scss';

.square-3 {
	background-color: white;
	margin-top: -50px;
	z-index: 5;
	display: grid;
	grid-gap: 10px;
	max-width: var(--page-w);
	margin-bottom: 50px;

	@media screen and (min-width: 1200px) {
		grid-template-columns: repeat(3, 1fr);
	}

	@media screen and (max-width: 1199px) {
		width: var(--page-w);
		margin-bottom: -35px;
	}

	& .card {
		width: var(--card-w);
		grid-template-rows: 150px 50px 220px 40px;

		display: grid;
		justify-items: center;

		& img {
			padding: 5px;
			height: 150px;
		}

		& p {
			font-size: 17px;
			width: 300px;
			color: var(--grafit);
		}

		& .rounded-button {
			min-width: 300px;
			padding: 27px 0;
			font-size: 18px;
		}
		& h2 {
			width: 200px;
			font-size: 22px;
			text-align: center;
			color: var(--terrakot);
		}

        @media screen and (max-width: 1199px) {
			grid-template-rows: auto auto auto auto;

            & p {
                font-size: 16px;
                padding: 0;
                width: 90vw;
                color: var(--grafit);
            }

            & .rounded-button {
                padding: 15px 0;
                width: 90vw;
                font-size: 18px;
            }
            & h2 {
                width: 200px;
                font-size: 22px;
                text-align: center;
                color: var(--terrakot);
            }
		}
	}

	& :nth-child(1) .rounded-button {
		background-color: var(--terrakot);
	}
	& :nth-child(2) .rounded-button {
		background-color: var(--grafit);
	}
	& :nth-child(3) .rounded-button {
		background-color: var(--green);
	}
}
