@use './c.scss';

@font-face {
    font-family: 'Creata';
    src: url('./fonts/Creata-Regular.eot');
    src: local('Creata Regular'), local('Creata-Regular'),
        url('./fonts/Creata-Regular.eot?#iefix') format('embedded-opentype'),
        url('./fonts/Creata-Regular.woff2') format('woff2'),
        url('./fonts/Creata-Regular.woff') format('woff'),
        url('./fonts/Creata-Regular.ttf') format('truetype');
    font-weight: 100;
    font-style: normal;
}
* {
    -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none;   /* Chrome/Safari/Opera */
    -khtml-user-select: none;    /* Konqueror */
    -moz-user-select: none;      /* Firefox */
    -ms-user-select: none;       /* Internet Explorer/Edge */
    user-select: none;
    :hover {
        transition: all ease 0.05s;
    }
    box-sizing: border-box;
    @media screen and (max-width: 1199px) {
        -ms-overflow-style: none;
        scrollbar-width: none;
        &::-webkit-scrollbar {
            display: none;
        }
    }
}

h1 {
    font-size: 2rem;
}

h1, h2, h3 {
    margin: 0;
    padding: 0;
    font-weight: 100;
    // line-height: 1.2em;

    @media screen and (max-width: 1199px) {
        padding: 0 10px;
    }
}

h1 {
    @media screen and (max-width: 1199px) {
        font-size: 1.8em;
    }
}

p {
    line-height: 1.6em;
    white-space: pre-line;

    @media screen and (max-width: 1199px) {
        padding: 0 10px;
    }
}

a {
    text-decoration: none;
    color: var(--grafit);
    align-self: center;

    &:hover {
        color: var(--terrakot)
    }
}
html, body, #root {
    height: 100%;
}
body {
    font-family: 'Creata';
    padding: 0;
    margin: 0;

    &.modal-open {
        overflow: hidden;
    }

    &::-webkit-scrollbar {
        display: none;
    }
}
.page {
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: var(--backgroud);
}

.underline {
    width: 100vw;
    height: 5px;
    // background: linear-gradient(to right, var(--terrakot) 25%, var(--grafit) 25% 50%, var(--yellow) 50% 75%, var(--green) 75% 100%);
    background: linear-gradient(to right, var(--grafit) 33%, var(--green) 33% 66%, var(--terrakot) 66%);
}

.fa {
    align-self: center;
    cursor:pointer;
    color: var(--gray);
}

.panels {
    margin-top: 40px;
    display: grid;
    grid-template-columns: 270px 900px;
    gap: 20px;
    width: var(--page-w);
}

.container {
    margin: 0;
    padding: 0;
    width: 100%;
    height: 100%;
    transition: all ease 0.2s;

    &_blured {
        filter: blur(2px) brightness(.5);
    }
}

.page-content {
    overflow-x: hidden;
}

.placeholder {
    height: 100vh;
    width: 100vw;
    background-color: var(--grafit);
    position: fixed;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    &__logo {
        margin-left: 175px;
        margin-top: -20%;
        height: 200px;
    }

    &__tribal {
        left: 0;
        height: 100vh;
        position: absolute;
    }

    &__grid {
        margin-left: 175px;
        flex-direction: row;
        display: flex;
        flex-wrap: wrap;
        gap: 30px;

        & > span {
            color: white;
            display: flex;
            flex-direction: column;
            gap: 10px;
            max-width: 300px;
            &:first-child {
                text-align: end;
            }

            & > span {
                &:first-child {
                    font-size: 1.5rem;
                    margin-bottom: 30px;
                    color: var(--terrakot);
                }

                &:nth-child(2) {
                    font-size: 1.3rem;
                }
            }
        }
    }
}