.career-slider-container {
	margin-top: 50px;
	position: relative;
	width: 100vw;
	height: 400px;
	display: flex;
	align-items: center;
	justify-content: center;

	@media screen and (max-width: 700px) {
		height: 830px;
		margin-top: -150px;
	}
}

.career-slider {
	width: 100%;
	height: 100%;
	text-align: center;
	overflow: hidden;
	position: relative;
	animation: shake-horizontal 20s cubic-bezier(0.36, 0.07, 0.19, 0.97) both
		infinite;
	animation-delay: 2s;

	&-wrapper {
		z-index: 50;
		max-width: 100%;
		top: 0;
		left: 0;
		right: 0;
		position: absolute;
	}

	& div > .swiper-slide:last-child {
		::before {
			content: none;
		}
	}
}

.slides {
	display: flex;
	overflow-x: scroll;
	position: relative;
	scroll-behavior: smooth;
	scroll-snap-type: x mandatory y;
}

.career-slider-item {
	width: 900px;
	margin-left: -250px;
	display: flex;
	flex-direction: row;
	justify-content: space-between;

	@media screen and (max-width: 1199px) {
		width: 100vw;
		margin-left: 0;
	}

	@media screen and (max-width: 700px) {
		flex-direction: column;
		justify-content: space-around;
	}

	&__tail_animate {
		display: inline-block;
		transition: all ease 0.5s;
	}

	&__img {
		height: 350px;
		margin: 20px 80px 20px 20px;
		transition: all ease 0.5s;

		@media screen and (max-width: 700px) {
			height: 280px;
			position: absolute;
			left: 50%;
			transform: translateX(-50%);
		}

		@media screen and (max-width: 800px) {
			margin: 0;
		}

		&_plus {
			opacity: 1;
			z-index: -1;
			height: 350px;
			margin: 20px 80px 20px 20px;
			position: absolute;

			@media screen and (max-width: 700px) {
				height: 280px;
				left: 50%;
				transform: translateX(-50%);
			}

			@media screen and (max-width: 800px) {
				margin: 0;
			}
		}
	}
	&__head {
		white-space: nowrap;
		margin: 20px 0;
		text-align: left;
		line-height: 50px;
		color: var(--terrakot);
		font-size: 22px;

		@media screen and (max-width: 700px) {
			margin-top: 550px;
			padding-left: 20px;
			text-align: center;
			font-size: 1.2em;
		}
	}

	&__heart {
		margin-bottom: -25px;
		display: inline-block;
		width: 60px;
	}

	&__text {
		text-align: left;
		color: var(--grafit);

		@media screen and (max-width: 1199px) {
			padding-left: 20px;
		}
	}

	&_unactive {
		& .career-slider-item__img {
			opacity: 0;
		}

		& .career-slider-item__tail_animate {
			transform: translateY(-15px);
			opacity: 0;
		}
	}

	&_multi {
		@media screen and (min-width: 1200px) {
			& .career-slider-item__img {
				&:hover {
					animation: shake-vertical 10s cubic-bezier(0.36, 0.07, 0.19, 0.97);

					& ~ img {
						animation: shake-vertical 10s cubic-bezier(0.36, 0.07, 0.19, 0.97);
					}
				}
			}
		}
	}

	&::before {
		position: absolute;
		z-index: -1;
		content: '';
		left: 0;
		right: -450px;
		bottom: 60px;
		height: 3px;
		border-bottom: dotted 3px var(--terrakot);
	}

	@media screen and (max-width: 1199px) {
		&::before {
			display: none;
		}
	}
}

@media (max-width: var(--page-w)) {
	.career-slider-item {
		margin-left: 0px;
	}
}

@keyframes shake-horizontal {
	1%,
	3% {
		transform: translateX(-2px);
	}

	2%,
	4% {
		transform: translateX(4px);
	}
	5% {
		transform: translateX(0);
	}
	100% {
		transform: translateX(0);
	}
}

@keyframes shake-vertical {
	1%,
	3% {
		transform: translateY(-2px);
	}

	2%,
	4% {
		transform: translateY(4px);
	}

	5% {
		transform: translateY(0);
	}
	100% {
		transform: translateY(0);
	}
}

@keyframes fadeIn {
	0% {
		opacity: 0;
	}
	99% {
		opacity: 0;
	}
	100% {
		opacity: 1;
	}
}
