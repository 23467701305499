.career-cards {
	padding-bottom: 30px;
	width: 100%;
	background-color: var(--dark-gray);
	display: flex;
	flex-direction: column;
	justify-items: space-between;
	align-items: center;

	&__header {
		font-size: 24px;
		color: var(--grafit);
		font-weight: bold;
		margin: 30px 0;
	}

	&__cards {
		width: 800px;
		display: grid;
		grid-template-columns: repeat(3, 1fr);
		grid-auto-rows: minmax(100px, auto);
		gap: 20px;

		@media screen and (max-width: 700px) {
			grid-template-columns: repeat(2, 50vw);
			width: 100vw;
			gap: 0
		}
	}

	&__card {
        margin-left: 50%;
        transform: translateX(-50%);
		position: relative;
		width: 250px;
		height: 250px;
		background-size: cover;
		background-position: center;

		@media screen and (max-width: 700px) {
			width: 180px;
			height: 180px;
		}

		& img {
			position: absolute;
			width: 250px;
			height: 250px;
			transition: all ease 0.3s;

			@media screen and (max-width: 700px) {
				width: 180px;
				height: 180px;
			}
		}

		& div {
			display: flex;
			flex-direction: row;
			justify-content: center;
			align-items: center;
			padding: 10px;
			opacity: 0;
			z-index: 5;
			position: absolute;
			top: 0;
			left: 0;
			width: 230px;
			height: 230px;
			position: absolute;
			text-align: center;
			transition: all ease 0.3s;

			@media screen and (max-width: 700px) {
                font-size: 0.8em;
				width: 180px;
				height: 180px;
			}
		}

		&:hover div {
			color: var(--white);
			opacity: 1;
			border-radius: 50%;
			background-color: var(--terrakot);
		}

		&:hover img {
			opacity: 0;
		}
	}
}
