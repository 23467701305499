.footer {
    font-size: small;
    margin: 50px 0 0 0;
    padding: 0;
    width: var(--page-w);
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-gap: 10px;
    margin-bottom: 15px;
    height: 170px;
    margin-left: 10px;

    & img {
        align-self: flex-start;
        max-width: 150px;
        padding-bottom: 5px;
    }

    & :nth-child(4) {
        & .fa {
            background-color: var(--white);
            color: var(--grafit);
            display: flex;
            align-items: center;
            justify-content: center;
            height: 20px;
            width: 27px;
            margin: 0;
            padding: 0;
            border-radius: 30px;
            &:hover {
                background-color: var(--grafit);
                color: var(--white);
            }
        }

    }

    & :nth-child(3) {
        & :nth-child(5) {
            margin-top: 7px;
        }

    }

    &-column {
        max-height: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: flex-start;
        gap: 5px;

        & .rounded-button {
            color: var(--white);
            border-radius: 5px;
            align-self: flex-start;
            width: 70%;
            background-color: var(--terrakot);
        }

        & a, h3, h4 {
            margin: 0;
            padding: 0;
            width: 100%;
        }

        & h3 {
            margin-bottom: 5px;
            font-size: 16px;
        }

        & h4 {
            font-size: 14px;
        }

        & a {
            cursor: pointer;
            font-size: 14px;

            &[href^="tel:"] {
                & span {
                    color: var(--grafit);
                }
            }
            &:hover {
                color: var(--terrakot);
            }
        }

        & * {
            text-align: left;
            color: var(--grafit);
        }
    }

    &__copyright {
        width: 100%;
        background-color: var(--grafit);
        color: var(--grafit);
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 5px;

        @media screen and (max-width: 1199px) {
            font-size: 0.8em;
            padding: 5px 0;
        }

        & > span {
            color: var(--white);
        }
        & > span:nth-child(2) {
            color: var(--grafit);
            font-size: 12px;
        }
    }
}

.footer-mobile {
    width: 100vw;
    display: flex;
    flex-direction: column;
    &__row {
        width: 100%;
        display: grid;
        grid-template-columns: 50% 50%;

        & > span {
            text-align: center;
            color: white;
            background-color: var(--green);
            padding: 5px 0;
            &:first-child {
                background-color: var(--terrakot);
            }
        }
    }
}