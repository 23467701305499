@use './c.scss';

.img-preview {
    position: relative;
    align-self: center;
    & img {
        max-width: 150px;
        border: var(--gray) 1px solid;
        border-radius: 30px;
        margin-top: 5px;
    }
    & i {
        position: absolute;
        top: 8px;
        right: 8px;
    }
}
.modal {
    position: fixed;
    z-index: 1000;
    top: 50%;
    left: 50%;
    min-width: 300px;
    transform: translate(-50%, -50%);
    background-color: var(--backgroud);
    &-header {
        font-size: medium;
        color: var(--white);
        padding: 7px;
        background-color: var(--terrakot);
        height: 30px;
        display: flex;
        justify-content: space-between;
        align-items: center;

    }
    &-input {
        display: flex;
        flex-direction: column;
        & input {
           font-family: inherit;
           margin-top: 3px;
           padding: 5px;
           border: none;
           border-bottom: 1px solid var(--gray);
           &:focus {
            outline: none;
            border-bottom: 1px solid var(--terrakot);
            }
        }
    }
    & .file-upload {
        font-size: medium;
        text-align: center;
        color: var(--grafit);
        border: none;
        display: inline-block;
        padding: 6px 12px;
        cursor: pointer;
        input[type="file"] {
            display: none;
        }
        &:hover {
            background-color: var(--light-white);
        }
    }
}
.modal-dimmer {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 999;
    background-color: rgba($color: var(--white), $alpha: .2);
    backdrop-filter: blur(3px);
}