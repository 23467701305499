.t {
    &_bold {
        font-weight: bold;
    }

    &_head {
        font-size: var(--text-size-head);
    }

    &_contrast {
        padding: 10px;
        border-radius: 15px;
        background-color: rgba($color: #000000, $alpha: .3);
    }

    &_center {
        text-align: center;
    }
    &_wide {
        width: var(--page-w);
    }
    &_mid {
        width: var(--1000-w);
    }
    &_terrakot {
        color: var(--terrakot)
    }
    &_grafit {
        color: var(--grafit)
    }
    &_orange {
        color: var(--terrakot)
    }
    &_18 {
        font-size: 18px;
    }
    &_active {
        color: var(--grafit);
        cursor: pointer;

        &:hover {
            color: var(--terrakot);
        }
    }

    &_active-white-terrakot {
        color: var(--white);
        cursor: pointer;

        &:hover {
            color: var(--terrakot);
        }
    }

    &_active-yellow-terrakot {
        color: var(--yellow);
        cursor: pointer;

        &:hover {
            color: var(--terrakot);
        }
    }

    &_white {
        color: white
    }

    @for $i from 1 through 100 {
        &_margin-y-#{$i} {
            margin: #{$i}px 0;
        }
    }

    &_hover-yellow {
        transition: all .3s ease;
        cursor: pointer;

        &:hover {
            color: var(--yellow);
        }
    }
}

.mt {
    &_giant {
        margin-top: 100px;
    }

    &_big {
        margin-top: 70px;
    }

    &_mid {
        margin-top: 40px;
    }

    &_ltl {
        margin-top: 20px;
    }
}
