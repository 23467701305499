.about-router {
    margin-top: 30px;
    display: flex;
    flex-direction: column;
    align-items: center;

    &__navbar {
        width: 650px;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        border: solid 1px var(--gray);
        border-radius: 25px;
        line-height: 50px;
        gap: 20px;

        & div {
            cursor: pointer;
            padding: 0 20px;
            border-radius: 25px;
            line-height: 50px;
            text-align: center;
            align-items: center;
            color: var(--grafit);

            &.active {
                background-color: var(--grafit);
                color: var(--white);
            }

            &:hover {
                box-shadow: inset 50px 100px 350px -100px rgba($color: #000000, $alpha: .1);
            }

            &:active {
                box-shadow: inset 50px 100px 350px -100px rgba($color: #000000, $alpha: .3);
            }
        }
    }

    &__cards {
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        grid-template-rows: repeat(3, 1fr);
        gap: 30px;
    }

    & .about-router__card:first-child {
        grid-column-start: 2;
        grid-column-end: 4;
    }

    .about-router__card:first-child::before,
    .about-router__card:nth-child(3)::before,
    .about-router__card:nth-child(6)::before {
        background-color: var(--terrakot);
    }

    .about-router__card:nth-child(2)::before,
    .about-router__card:nth-child(4)::before {
        background-color: var(--yellow);
    }

    .about-router__card:nth-child(5)::before,
    .about-router__card:nth-child(7)::before {
        background-color: var(--grafit);
    }


    &__card {
        &::before{
            content: '';
            position: absolute;
            width: 100%;
            height: 3px;
            bottom: 0;
        }

        padding: 0 20px;
        font-size: 14px;
        margin: 10px;
        width: 330px;
        display: flex;
        flex-direction: row;
        position: relative;
        font-size: 16px;

        & img {
            object-fit: cover;
            height: 160px;
            width: 120px;
            margin-bottom: 15px;
            margin-left: 20px;
        }

        & div {
            margin: 5px;
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            align-items: flex-start;
        }

        & div:first-child {
            color: var(--grafit);
        }
    }
}