@use './c.scss';

.header {
	@media screen and (min-width: 1200px) {
		width: 100%;
		z-index: 1000;
		position: sticky;
		top: 0;
		max-width: var(--page-w);
		display: flex;
		flex-direction: column;
		font-size: 16px;
		background-color: var(--backgroud);

		&.scrolled {
			border-radius: 10px;
			background-color: var(--backgroud);
		}
		&-row {
			display: flex;
			flex-direction: row;
			justify-content: space-between;
			padding: 0 15px;

			&.reverse {
				flex-direction: row-reverse;
				justify-content: end;
				gap: 5px;
			}
			& i {
				line-height: 50px;
				margin-right: 10px;

				&:hover {
					color: var(--terrakot);
				}
				&.active {
					color: var(--terrakot);
				}
			}
			& a {
				line-height: 50px;
				margin-right: 15px;
			}

			& .header-logo {
				cursor: pointer;
				margin-top: -15px;
				transition: all ease 0.3s;
				& img {
					width: 230px;
				}

				&_scrolled {
					margin-top: 0px;
					margin-left: 5px;

					& img {
						max-width: 200px;
					}
				}

				&.hidden {
					visibility: hidden;
					margin-left: -290px;
					opacity: 0;
				}
			}

			& .header-button,
			.header-dropdown {
				text-transform: uppercase;
				line-height: 50px;
				padding: 10px 15px;
			}
		}

		&-button,
		& .header-dropdown,
		& .button-reusable {
			white-space: nowrap;
			cursor: pointer;
			color: var(--grafit);
			&.active {
				background-color: var(--terrakot);
				color: white;
			}
			&:hover {
				color: var(--terrakot);
				box-shadow: inset 50px 100px 350px -100px rgba($color: #000000, $alpha:
							0.1);
			}

			&:active {
				box-shadow: inset 50px 100px 350px -100px rgba($color: #000000, $alpha:
							0.3);
			}
		}
	}
	@media screen and (max-width: 1199px) {
		width: 270px;
		position: fixed;
		padding: 25px;
		top: 50px;
		right: -500px;
		border-bottom-left-radius: 50px;
		z-index: 1000;
		background-color: var(--white);
		display: flex;
		flex-direction: column;
		justify-content: space-between;
		transition: all 0.5s;
		box-shadow: var(--soft-block-shadow);
		text-transform: uppercase;

		&_opened {
			right: 0;
		}

		& .header-row {
			display: flex;
			flex-direction: column;
			gap: 15px;

			&_right {
				align-items: flex-end;
			}
		}

		& .header-select {
			width: 50px;
		}
	}
}

.mobile-header {
	z-index: 1000;
	width: 100%;
	padding-right: 20px;
	position: sticky;
	top: 0;
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	transition: all 1s;
	height: 50px;
	background-color: white;
	box-shadow: var(--soft-block-shadow);

	& > i {
		color: var(--grafit);
	}

	&__header-logo {
		& > img {
			height: 50px;
		}
	}
}

.header-dropdown {
	width: auto;
	position: relative;
	cursor: pointer;
	color: var(--grafit);
}

.header-select {
	margin-top: 3px;
	font-size: 15px;
	border: none;
	color: var(--grafit);

	&:focus,
	:active {
		border: none;
	}
	& option {
		border: none;
	}
}

.header-dropdown-list {
	max-width: 300px;
	margin-top: 5px;
	margin-left: -15px;
	z-index: 100;
	border-bottom-left-radius: 10px;
	border-bottom-right-radius: 10px;
	background-color: var(--white);
	transition: all ease 0.3s;
	overflow: hidden;
	position: absolute;
	visibility: hidden;
	max-height: 0;
	cursor: pointer;

	& .button-reusable {
		padding: 5px 30px;
	}
}
.header-dropdown-list.opened {
	visibility: visible;
	max-height: 300px;
}
.header-dropdown-list.active {
	color: var(--terrakot);
}
.header-dropdown-list-dimmer {
	z-index: 2;
	position: absolute;
	width: 3500px;
	height: 3500px;
	top: -1500px;
	left: -1500px;
	right: 0;
	bottom: 0;
}
