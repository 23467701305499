.foodservice-filters {
    font-size: large;

    @media screen and (max-width: 1199px) {
        max-height: 100vh;
        overflow-y: auto;
        box-shadow: var(--soft-block-shadow)
    }

    & h2 {
        margin-top: 25px;
        font-size: 20px;
    }

    &__reset-filters {
        width: 100%;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 15px;

        &__button {
            border: solid 1px var(--gray);
            border-radius: 5px;
            background-color: white;
            color: var(--grafit);
            width: 120px;
            text-align: center;
            padding: 10px 5px;
        }
    }
}

.filters-toggle-button {
    z-index: 2000;
    position: fixed;
    background-color: var(--terrakot);
    top: var(--mobile-button-heigth);
    left: 10px;
    width: 40px;
    height: 40px;
    border-radius: 50%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}

.filters-panel {
    z-index: 1000;
    position: fixed;
    top: 0;
    bottom: 0;
    left: -500px;
    background-color: var(--white);
    transition: all .5s;

    &_opened {
        left: 0
    }
}

.foodservice-filters-items {
    margin: 0;
    padding: 0;

    &__item {
        display: grid;
        grid-template-columns: 200px 40px;
        padding: 10px;
        list-style: none;
        margin-top: 5px;
        background-color: var(--checkbox-gray);
        color: var(--grafit);
        font-size: var(--font-little);
    }

}

.category-item {
    background-color: var(--checkbox-gray);

    & li {
        list-style: none;
        margin-top: 5px;
    }

    & ul {
        margin: 0;
        padding: 10px;
    }

    h2 {
        border-bottom: solid 1px var(--gray);
        margin-top: 5px;
        padding: 10px;
    }
}