.food-service-page {
    display: grid;
    position: relative;
    grid-template-columns: repeat(2, 600px);
    grid-auto-rows: 500px;

    @media screen and (max-width: 1199px) {
        grid-template-columns: 100vw;
        grid-auto-rows: auto;
        gap: 10px;

        & > div:not(:first-child) {
            padding: 5px;
        }
    }

    &__photo {
        position: relative;
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        & img {
            max-width: 100%;
            max-height: 100%;
        }
    }
    &__labels {
        position: absolute;
        top: 0;
        right: 0;
        display: flex;
        flex-direction: column;
        align-items: flex-end;

        & span {
            width: fit-content;
            color: var(--white);
            font-size: smaller;
            border-radius: 5px;
            margin-top: 5px;
            padding: 5px;
        }

        & span:nth-child(1) {
            background-color: var(--terrakot);
        }
        & span:nth-child(3n+2) {
            background-color: var(--green);
        }
        & span:nth-child(3n+3) {
            background-color: var(--yellow);
        }
        & span:nth-child(3n+4) {
            background-color: var(--grafit);
        }
    }

    &__top-right {
        padding-left: 15%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: flex-start;

        & h1 {
            color: var(--terrakot);
            margin-bottom: 15px;
        }

        & ul {
            list-style-type: none;
            margin: 0;
            padding: 0;

            & li {
                color: var(--font-gray);
                display: flex;
                flex-direction: row;
                align-items: center;
                gap: 15px;
                margin-top: 10px;

                & img {
                    width: 25px;
                }
            }
        }
    }

    &__bottom-left {
        padding: 5px 25px;
        & h1 {
            color: var(--grafit);
        }

        & p {
            color: var(--font-gray);
        }
    }

    &__bottom-right {
        padding: 5px 25px;
        & h1 {
            color: var(--grafit);
        }

        & p {
            color: var(--font-gray);
        }
    }

    &__specs-header {
        display: flex;
        flex-direction: row;
        color: var(--grafit);
        margin-top: 25px;

        & i {
            font-size: medium;
            margin-left: 15px;
            color: var(--grafit) !important;
        }

        &:hover {
            color: var(--terrakot);
            cursor: pointer;
        }

    }

    &__specs {
        max-height: 0;
        visibility: hidden;
        overflow: hidden;
        width: fit-content;
        list-style-type: none;
        margin: 0;
        padding: 10px;
        margin-left: 40px;
        background-color: var(--white);
        -webkit-box-shadow: 4px 4px 8px 0px rgba(34, 60, 80, 0.2);
        -moz-box-shadow: 4px 4px 8px 0px rgba(34, 60, 80, 0.2);
        box-shadow: 4px 4px 8px 0px rgba(34, 60, 80, 0.2);
        transition: all ease .3s;

        & li {
            color: var(--font-gray);
            align-items: center;
            color: var(--grafit);
            padding: 7px 0;

            &:hover {
                color: var(--terrakot);
                cursor: pointer;
            }
        }

        &_open {
            margin: 15px 0;
            visibility: visible;
            max-height: 600px;
        }
    }
}