:root {
    --new-brown: #4C3C3D;
    --grafit: #3D4543;
    --grafit-50: #3D454380;

    --yellow: #FFD100;
    --skin: #CDA787;
    --terrakot: #E64B38;

    --green: #AAC17E;
    --green-50: #AAC17E80;

    --blue: #21525C;
    --blue-50: #21525C80;

    --orange: #E35205;
    --light-white: #f7f1f1;
    --checkbox-gray: #f2f2f2;
    --checkbox-dark-gray: #828282;
    --white: #FFFFFF;
    --gray: #E1E0D6;
    --dark-gray: #f0efea;
    --darker-gray: #d3d2d0;
    --font-gray: #4c4c4c;
    --backgroud: #fcfcfc;

    --soft-block-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;

    --mobile-button-heigth: 85vh;

    --l-width: 800px;

    --font-little: 16px;

    @media screen and (min-width: 1200px) {
        --page-w: 1200px;
        --card-w: 400px;

        --500-w: 500px;
        --630-w: 630px;
        --650-w: 650px;
        --1000-w: 1000px;
        --l-width: 800px;

        //home
        --600-h: 600px;
        --300-h: 300px;
        --135-h: 135px;
        --410-h: 450px;

        --modal-w: var(--500-w);

        --text-size-head: 45px;
        --text-size-36: 36px;
    }

    @media screen and (max-width: 1199px) {
        --page-w: 100vw;
        --card-w: 300px;

        --500-w: 100%;
        --630-w: 100%;
        --650-w: 100%;
        --1000-w: 100vw;
        --l-width: 100vw;

        //home
        --600-h: 450px;
        --300-h: 150px;
        --135-h: 75px;
        --410-h: 340px;

        --modal-w: var(--500-w);

        --text-size-head: 2rem;
        --text-size-36: 1.6rem;
    }

    @media screen and (max-width: 699px) {
        --page-w: 100vw;
        --card-w: 100%;

        --500-w: 100%;
        --630-w: 100%;
        --650-w: 100%;
        --1000-w: 100vw;
        --l-width: 100vw;

        //home
        --600-h: 450px;
        --300-h: 150px;
        --135-h: 75px;
        --410-h: 340px;

        --modal-w: var(--500-w);

        --text-size-head: 2rem;
        --text-size-36: 1.6rem;
    }
}
