@use './c.scss';

$height: 600px;

.simple-slider-container {
    position: relative;
    width: 100vw;
    height: $height;
    display: flex;
    align-items: center;
    justify-content: center;
}

.simple-slider {
    width: 100%;
    max-width: var(--page-w);
    height: 100%;
    text-align: center;
    overflow: hidden;
    position: relative;

    &-wrapper {
        z-index: 50;
        max-width: var(--page-w);
        top: 0;
        left: 0;
        right: 0;
        position: absolute;
    }

    & .about-slide-prev {
        position: absolute;
        top: 275px;
        left: 15px;
        z-index: 5;
        border-radius: 50%;
        height: 50px;
        width: 50px;
        color: white;
        background-color: var(--terrakot);
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        transition: all ease 0.5s;

        &:hover {
            color: var(--white);
            box-shadow:
            0 0 0 5px rgba($color: white, $alpha: .1),
            0 0 0 10px rgba($color: white, $alpha: .2),
            0 0 0 15px rgba($color: white, $alpha: .4);
        }

        &:active {
            filter: brightness(80%);
        }

        & i {
            font-size: 18px;
        }
    }
    & .about-slide-next {
        position: absolute;
        top: 275px;
        right: 15px;
        z-index: 5;
        border-radius: 50%;
        height: 50px;
        width: 50px;
        color: white;
        background-color: var(--terrakot);
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        transition: all ease 0.5s;

        &:hover {
            color: var(--white);
            box-shadow:
            0 0 0 5px rgba($color: white, $alpha: .1),
            0 0 0 10px rgba($color: white, $alpha: .2),
            0 0 0 15px rgba($color: white, $alpha: .4);
        }

        &:active {
            filter: brightness(80%);
        }

        & i {
            font-size: 18px;
        }
    }
}

.slides {
    display: flex;
    overflow-x: scroll;
    position: relative;
    scroll-behavior: smooth;
    scroll-snap-type: x mandatory y;
}

.simple-slider_item {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-shrink: 0;
    width: 100%;
    height: $height;
    scroll-snap-align: center;
    margin-right: 0px;
    box-sizing: border-box;
    background: var(--white);
    transform-origin: center center;
    transform: scale(1);
    background-size: cover;
    background-position: center;
    box-shadow: inset 50px 100px 350px -100px #000000;

}
.simple-slider-item>h1 {
    cursor: pointer;
}

