@use './c.scss';

.slider-container {
    position: relative;
    width: 100vw;
    height: var(--600-h);
    display: flex;
    align-items: center;
    justify-content: center;
} 

.slider {
    width: 100%;
    max-width: 100vw;
    height: 100%;
    text-align: center;
    overflow: hidden;
    position: relative;

    &-wrapper {
        max-width: var(--page-w);
        width: 100%;
        position: absolute;
        top: 0;
        left: 50%;
        z-index: 50;
        transform: translate(-50%, -50%);
    }
}

.slides {
    display: flex;
    overflow-x: scroll;
    position: relative;
    scroll-behavior: smooth;
    scroll-snap-type: x mandatory y;
}

.slider-item {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-shrink: 0;
    width: 100%;
    height: var(--600-h);
    scroll-snap-align: center;
    margin-right: 0px;
    box-sizing: border-box;
    background: var(--white);
    transform-origin: center center;
    transform: scale(1);
    background-size: cover;
    background-position: center;
    box-shadow: inset 50px 100px 350px -100px #000000;

    & h1 {
        padding-left: 10px;
        position: absolute;
        max-width: var(--650-w);
        text-align: left;
        left: 0;
        top: var(--135-h);
        text-shadow: rgba($color: #000000, $alpha: .8) 1px 0 20px;

        @media screen and (max-width: 1199px) {
            width: 100%;
            border-radius: 0;
            font-size: 1.8em;
        }
    }

    & .rounded-button {
        position: absolute;
        left: 0;
        top: var(--300-h);
        width: 140px;
        line-height: 40px;
        background-color: var(--terrakot);
    }
}
.slider-item>h1 {
    cursor: pointer;
}

.slider-controls {
    padding-left: 10px;
    position: absolute;
    max-width: var(--page-w);
    top: var(--410-h);
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 20px;
}

.slider-controls .circle{
    color: var(--white);
    font-size: 20px;
    cursor: pointer;
}
.circle:hover {
    color: var(--terrakot) !important
}
.circle.active {
    color: var(--terrakot) !important
}
.slider-controls-buttons {
    display: flex;
    flex-direction: row;
    margin-right: 20px;
}
.slider-controls-buttons>div {
    height: 40px;
    width: 50px;
    color: white;
    display: flex;
    flex-direction: column;
    border-top-right-radius: 20px;
    border-bottom-right-radius: 20px;
    // backdrop-filter: blur(4px);
    background-color: var(--terrakot);
    cursor: pointer;

    & span {
        font-size: 40px;
        line-height: 40px;
        text-align: center;
    }
}
.slider-controls-buttons>div:hover {
    filter: brightness(85%);
}
.slider-controls-buttons :first-child{
    border-top-left-radius: 20px;
    border-bottom-left-radius: 20px;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
}
