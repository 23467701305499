.waste-page {
	margin: auto;
	width: 70vw;
	max-width: 900px;
	// padding-top: 70px;
	/* background: linear-gradient(
        45deg,
        #e1e0d6 25%,
        transparent 25%,
        transparent 75%,
        #e1e0d6 75%
      ),
      linear-gradient(
          45deg,
          #e1e0d6 25%,
          transparent 25%,
          transparent 75%,
          #e1e0d6 75%
        )
        0.1875em 0.1875em,
      radial-gradient(at 50% 0, #e6e5db, #e1e0d6);
    background-size: 0.375em 0.375em, 0.375em 0.375em, 100% 100%; */

	.colored-list {
		& li {
			& .last {
				margin-top: 15px;
			}

			margin-top: -10px;
			&:nth-child(1) {
				&::marker {
					font-size: 30px
				}
				list-style-image: url('/marker_slice_green.svg');
			}
			&:nth-child(2) {
				&::marker {
					font-size: 30px
				}
				list-style-image: url('/marker_slice_yellow.svg');
			}
			&:nth-child(3) {
				&::marker {
					font-size: 30px
				}
				list-style-image: url('/marker_slice_terrakot.svg');
			}
			&:nth-child(4) {
				&::marker {
					font-size: 30px
				}
				list-style-image: url('/marker_slice_skin.svg');
			}
		}
	}

	.white-list {
		& li {

			&::marker {
				font-size: 30px
			}
			margin-top: -10px;
			list-style-image: url('/marker_slice.svg');

			& .last {
				margin-top: 15px;
			}

		}
	}

	a {
		text-decoration: none;
		&:hover {
			color: var(--terrakot);
		}
	}
}

.waste-navbar {
	width: 80%;
	margin: auto;
}
// @media (max-width: 1400px) {
// 	.waste-page {
// 		font-size: 135%;
// 	}
// }
// @media (max-width: 1200px) {
// 	.waste-page {
// 		font-size: 120%;
// 	}
// }

@media (max-width: 991.98px) {
	.waste-page,
	.waste-navbar {
		width: 100vw;
	}
	.waste-page {
		font-size: 100%;
	}
}

// p,
// h1,
// h2,
// h3,
// h4,
// h5,
// h6,
// li {
// 	font-family: 'HouschkaRoundedAlt';
// 	color: white;
// }

.orange > li,
.orange > h1,
.orange > h2,
.orange > h3,
.orange > h4,
.orange > h5,
.orange > h6 {
	color: var(--terrakot);
}
.yellow > li,
.yellow > h1,
.yellow > h2,
.yellow > h3,
.yellow > h4,
.yellow > h5,
.yellow > h6 {
	color: var(--yellow);
}
.colorsess {
	color: var(--grafit);
	color: var(--terrakot);
	color: var(--green);
	color: var(--skin);
	color: rgba(255, 255, 255, 1);
}

.menu-tab-text {
	color: white;
	background: linear-gradient(
		85deg,
		rgba(40, 40, 40, 0.3) 30%,
		rgba(250, 250, 250, 0.1) 80%
	);
	padding: 3rem;
}
.menu-tab {
	height: 30vh;
}
.menu-hover:hover .menu-tab-text {
	background: rgb(98, 98, 98);
	background: linear-gradient(
		85deg,
		rgba(227, 82, 5, 0.7) 0%,
		rgba(255, 255, 255, 0) 100%
	);
}
.menu-hover:hover .d-flex {
	display: block;
	box-shadow: inset 0 0 5rem rgba(227, 82, 5, 0.2);
	cursor: pointer;
}

.wrapper {
	display: grid;
	grid-template-columns: [col1-start] 25% [col2-start] 25% [col3-start] 25% [col4-start] 25% [col4-end];
	grid-template-rows: [row1-start] auto [row2-start] auto [row2-end];
	background-color: #fff;
	color: #444;
}
.box {
	color: #fff;
	display: block;
	padding: 2rem;
	// margin: 0.3rem;
}
.a {
	grid-column: col1-start / col3-start;
	grid-row: row1-start;
}
.b {
	grid-column: col1-start / col3-start;
	grid-row: row2-start;
}
.c {
	grid-column: col3-start / col4-end;
	grid-row: row1-start;
}
.e {
	grid-column: col3-start / col4-end;
	grid-row: row2-start;
}
.d {
	grid-column: col3-start / col4-end;
	grid-row: row1-start / row2-end;
	pointer-events: none;
}
.full {
	grid-column: col1-start / col4-end;
	grid-row: row1-start / row2-end;
}
.ac {
	grid-column: col1-start / col4-end;
	grid-row: row1-start;
}
.be {
	grid-column: col1-start / col4-end;
	grid-row: row2-start;
}
.g {
	grid-column: col1-start / col4-end;
	grid-row: row3-start;
}

@media (max-width: 991.98px) {
	.waste-page,
	.waste-navbar {
		width: 100vw;
	}
	.wrapper {
		display: grid;
		grid-template-columns: [col1-start] 100% [col1-end];
		grid-template-rows: [row1-start] auto [row2-start] auto [row3-start] auto [row4-start] auto [row5-start] auto [row5-end];
		background-color: #fff;
		color: #444;
	}
	.box {
		color: #fff;
		display: block;
		padding: 1rem;
		// margin: 0.3rem;
	}
	.a {
		grid-column: col1-start;
		grid-row: row1-start;
	}
	.b {
		grid-column: col1-start;
		grid-row: row2-start;
	}
	.c {
		grid-column: col1-start;
		grid-row: row3-start;
	}
	.d {
		grid-column: col1-start;
		grid-row: row4-start;
	}
	.full {
		grid-column: col1-start;
		grid-row: row1-start / row4-end;
	}
	.e {
		grid-column: col1-start;
		grid-row: row5-start;
	}
	.type-waste-title {
		padding: 1rem;
		margin-left: 0rem;
	}
	.menu-tab {
		height: 20vh;
	}
	.menu-tab-text {
		height: 20vh;
		padding: 0.5rem;
	}
}

.type-waste-title {
	padding: 1rem;
	margin-left: -4rem;

	@media (max-width: 991.98px) {
		margin-left: 0;
	}
}

.waste-white {
	&_text {
		color: white;
	}
}

.waste-separate-cards {
	margin-top: 15px;
	display: flex;
	flex-direction: row;
	flex-wrap: wrap;
	justify-content: center;
	gap: 30px;

	& > img {
		width: 270px;
		box-shadow: var(--soft-block-shadow);
	}
}

.waste-contacts {
	& * {
		list-style: none;
	}
	& > li {
		margin-top: 10px ;
		display: flex;
		flex-direction: row;
		gap: 5px;

		& > span {
			margin-top: 0 !important;
		}
	}
}

.waste-skin {
	background-color: var(--skin);
	&_text {
		color: var(--skin);
	}
}

.waste-grafit {
	background-color: var(--grafit);
	&_transparent {
		background-color: var(--grafit-50);
	}
	&_text {
		color: var(--grafit);
	}
}

.waste-yellow {
	&_text {
		color: var(--yellow);
	}
}

.waste-green {
	background-color: var(--green);
	&_transparent {
		background-color: var(--green-50);
	}
	&_text {
		color: var(--green);
	}
}

.waste-blue {
	background-color: var(--blue);

	&_transparent {
		background-color: var(--blue-50);
	}
	&_text {
		color: var(--blue);
	}
}

.waste-terrakot {
	background-color: var(--terrakot);
	&_text {
		color: var(--terrakot);
	}
}

.waste-types {
	padding-top: 10px;
	margin-left: -10px;
	display: grid;
	grid-template-columns: 50% 50%;
	gap: 10px;

	&__type {
		color: white;
	}

	&__image {
		background-position: center;
		background-size: cover;

		&:hover {
			box-shadow: inset 0 -200px 150px -100px rgba(0, 0, 0, 0.2);
		}
	}
}

.waste-divider {
	width: 100%;
	display: flex;
	justify-content: center;
	align-items: center;
}

.waste-contacts {
	display: grid;
	grid-template-columns: 50% 50%;

	@media (max-width: 991.98px) {
		grid-template-columns: 100%;
	}

	&__bye {
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;

		& > img {
			margin-top: 100px;
			margin-bottom: -100px;
		}
	}

	& > div {
		padding: 20px;
	}

	& :first-child {
		display: flex;
		flex-direction: column;
		gap: 5px;

		& > ul {
			padding-left: 0;

			& > li {
				& :nth-child(odd) {
					margin-top: 10px;
					color: var(--yellow);
				}

				& > a {
					color: white;
					&:hover {
						color: var(--terrakot);
					}
				}
			}
		}
	}


}
