.reciepes-details {
	display: grid;
	position: relative;
	grid-template-columns: repeat(2, 600px);
	grid-auto-rows: minmax(450px, auto);

    @media screen and (max-width: 1199px) {
        grid-template-columns: repeat(1, 100vw);
        grid-auto-rows: minmax(auto, auto);
    }


	&__photo {
		position: relative;
		display: flex;
		flex-direction: row;
		justify-content: center;
		align-items: center;
		& div {
			background-size: cover;
			background-position: top;
			width: 100%;
			height: 100%;

            @media screen and (max-width: 1199px) {
                height: 350px;
            }
		}
	}
	&__labels {
		position: absolute;
		top: 0;
		right: 0;
		display: flex;
		flex-direction: column;
		align-items: flex-end;

		& span {
			width: fit-content;
			color: var(--white);
			font-size: smaller;
			border-radius: 5px;
			margin-top: 5px;
			padding: 5px;
		}

		& span:nth-child(1) {
			background-color: var(--terrakot);
		}
		& span:nth-child(3n + 2) {
			background-color: var(--grafit);
		}
		& span:nth-child(3n + 3) {
			background-color: var(--yellow);
		}
	}

	&__top-right {
		padding-left: 15%;
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: flex-start;

        @media screen and (max-width: 1199px) {
            padding-left: 0;
        }

		& h1 {
			color: var(--terrakot);
			margin-bottom: 15px;
            @media screen and (max-width: 1199px) {
                font-size: 1.8em;
            }
		}

		& ul {
			list-style-type: none;
			margin: 0;
			padding: 0;

			& li {
				color: var(--font-gray);
				display: flex;
				flex-direction: row;
				align-items: center;
				gap: 15px;
				margin-top: 10px;

				& img {
					width: 25px;
				}
			}
		}
	}

	&__bottom-left {
		padding: 50px 25px;

        @media screen and (max-width: 1199px) {
            padding: 0;
        }

		& h1 {
			color: var(--grafit);
		}

		& ul {
			padding: 25px;
			-webkit-box-shadow: 4px 4px 8px 0px rgba(34, 60, 80, 0.2);
			-moz-box-shadow: 4px 4px 8px 0px rgba(34, 60, 80, 0.2);
			box-shadow: 4px 4px 8px 0px rgba(34, 60, 80, 0.2);
			background-color: var(--white);

			& li {
				color: var(--grafit) !important;
			}
		}
	}

	&__bottom-right {
		padding: 50px 25px;

        @media screen and (max-width: 1199px) {
            padding: 0;
        }

		& h1 {
			color: var(--grafit);
		}

		& p {
			white-space: pre-line;
			padding: 25px;
			-webkit-box-shadow: 4px 4px 8px 0px rgba(34, 60, 80, 0.2);
			-moz-box-shadow: 4px 4px 8px 0px rgba(34, 60, 80, 0.2);
			box-shadow: 4px 4px 8px 0px rgba(34, 60, 80, 0.2);
			background-color: var(--white);
			color: var(--grafit);
		}
	}
	&__icon {
		display: flex;
		flex-direction: row;
		justify-content: center;
		align-items: center;
		gap: 15px;
		& img {
			height: 80px;
		}
	}

	&__retail-products {
		margin-top: 15px;
		display: flex;
		flex-direction: row;
		justify-content: space-between;
		align-items: flex-start;
		gap: 50px;

        @media screen and (max-width: 1199px) {
            display: grid;
            grid-template-columns: 50vw 50vw;
            margin-bottom: 35px;
            grid-gap: 0;
            align-items: center;
            align-content: center;

            &__item {
                display: flex;
                flex-direction: column;
            }
        }

		&__photo {
			background-size: cover;
			background-position: top;
			width: 170px;
			height: 220px;
			margin-bottom: 15px;

            @media screen and (max-width: 1199px) {
                width: auto;
            }
		}

		& h3 {
			text-align: center;
			width: 150px;
			color: var(--grafit);
            @media screen and (max-width: 1199px) {
                width: auto;
                padding: 10px;
            }
		}
	}

	&__bottom-buttons {
		display: grid;
		grid-template-columns: auto auto;
		margin-top: 50px;
		gap: 30px;
		cursor: pointer;

		@media screen and (max-width: 1199px) {
			grid-template-columns: 100vw;
			margin-top: 5px;
			margin-bottom: 5px;
            gap: 5px;
		}

		& > div {
			padding: 25px 30px;
			color: var(--white);
			display: flex;
			flex-direction: column;
			border-radius: 5px;
			background-color: var(--terrakot);
			transition: all ease 0.2s;

            @media screen and (max-width: 1199px) {
                border-radius: 0;
                padding: 15px 0;
            }

			&:hover {
				-webkit-box-shadow: 4px 4px 8px 0px rgba(34, 60, 80, 0.4);
				-moz-box-shadow: 4px 4px 8px 0px rgba(34, 60, 80, 0.4);
				box-shadow: 4px 4px 8px 0px rgba(34, 60, 80, 0.4);
			}

			& > h3 {
				font-size: 28px;
				margin: 0;
			}

			& > div {
				margin-top: 5px;
				display: flex;
				flex-direction: row;
				justify-content: space-between;
				align-items: flex-end;

                @media screen and (max-width: 1199px) {
                    display: grid;
                    grid-template-columns: 50% 15%;
                }

				& > p {
					font-size: 14px;
					margin: 0;
					width: 320px;
					margin-right: 40px;
				}

				& > span {
					height: 50px;
					width: 50px;
					background-color: var(--yellow);
					border-radius: 50%;
					display: flex;
					justify-content: center;
					align-items: center;

                    @media screen and (max-width: 1199px) {
                        border-radius: 25px;
                    }
				}
			}
		}

		& > div:first-child {
			background-color: var(--grafit);

			& > div {
				& > span {
					background-color: var(--terrakot);
				}
			}
		}
	}
}
