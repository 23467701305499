.field-to-dish {
	display: grid;
	grid-template-columns: repeat(2, 900px);
	grid-auto-rows: 650px;

	@media screen and (max-width: 1199px) {
		grid-template-columns: repeat(1, 100vw);
		grid-auto-rows: auto;
	}

	&__photo {
		background-size: cover;
		background-position: center;
		@media screen and (max-width: 1199px) {
			// width: 100vw;
			min-height: 200px;
		}
	}

	&__text {
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: flex-start;
		color: var(--font-gray);
		white-space: pre-wrap;

		&-header {
			color: var(--grafit);
			font-size: 24px;
			width: 600px;
			display: flex;
			flex-direction: row;
			justify-content: flex-start;
			align-items: center;
			gap: 30px;
			margin-bottom: 20px;
			white-space: pre-wrap;

			& > img {
				height: 160px;
				margin: -30px;
			}

            @media screen and (max-width: 1199px) {
				overflow-x: auto;
                margin-top: 30px;
				width: 100vw;
				gap: 5px;

                & p {
                    text-align: justify;
                }

				& > h2 {
                    font-size: 1em;
                    word-wrap: break-word;
                }
                & > img {
                    height: 80px;
                    margin: 0
                }
			}
		}
	}

	&__text:nth-child(even) {
		padding-right: 20%;
		padding-left: 10%;
		@media screen and (max-width: 1199px) {
			padding: 0;
		}
	}

	&__text:nth-child(odd) {
		padding-right: 10%;
		padding-left: 20%;
		@media screen and (max-width: 1199px) {
			padding: 0;
		}
	}
}
