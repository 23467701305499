.toggle-switch {
    position: relative;
    display: inline-block;
    width: 45px;
    height: 24px;
  }

  .toggle-switch input {
    opacity: 0;
    width: 0;
    height: 0;
  }

  .toggle-slider {
    border-radius: 12px;
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: var(--checkbox-gray);
    -webkit-transition: .4s;
    transition: .4s;
    border: solid 1px var(--gray);
  }

  .toggle-slider:before {
    position: absolute;
    content: "";
    height: 18px;
    width: 18px;
    left: 3px;
    bottom: 2px;
    background-color: var(--checkbox-dark-gray);
    -webkit-transition: .4s;
    transition: .4s;
  }

  input:checked + .toggle-slider:before {
    background-color: var(--grafit);
  }


  input:checked + .toggle-slider:before {
    -webkit-transform: translateX(20px);
    -ms-transform: translateX(20px);
    transform: translateX(20px);
  }

  .toggle-slider:before {
    border-radius: 12px;
  }

  .custom-checkbox {
    display: block;
    position: relative;
    padding-left: 35px;
    margin-bottom: 12px;
    cursor: pointer;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
  }

  .custom-checkbox input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
  }

  .checkmark {
    position: absolute;
    top: 0;
    left: 0;
    height: 20px;
    width: 20px;
    background-color: white;
    border: solid 1px var(--gray);
  }

  .custom-checkbox:hover input ~ .checkmark {
    background-color: #ccc;
  }


  .checkmark:after {
    content: "";
    position: absolute;
    display: none;
  }

  .custom-checkbox input:checked ~ .checkmark:after {
    display: block;
  }

  .custom-checkbox__text {
    color: var(--grafit);
    font-size: var(--font-little);
  }
  .custom-checkbox__text_active {
    color: var(--terrakot);
    font-size: var(--font-little);
  }

  .custom-checkbox .checkmark:after {
    left: 5px;
    top: 5px;
    width: 10px;
    height: 10px;
    background-color: var(--terrakot);
  }