.contacts-cards {
    margin-top: 40px;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-auto-rows: minmax(20px, auto);
    gap: 35px;
    margin-bottom: 20px;

    @media screen and (max-width: 1199px) {
        grid-template-columns: repeat(2, 1fr);
    }

    @media screen and (max-width: 700px) {
        grid-template-columns: repeat(1, 1fr);
    }

    &__card {
        width: 325px;
        height: 350px;
        padding-left: 25px;
        background-color: white;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: flex-start;

    }

    &__description {
        white-space: pre-line;
        color: var(--grafit);
        font-size: medium;
        max-width: 300px;
        height: 70px;
        padding-right: 5px;
    }

    &__contacts {
        display: grid;
        & span:nth-child(2) {
            margin-top: 10px;
        }
    }

    &__contact-row {
        display: flex;
        align-items: center;
        &>img {
            width: 25px;
            margin-right: 10px;
        }
    }

    &__tel {
        font-size: large;

        &:hover {
            color: var(--terrakot)
        }
    }

    &__mail {
        font-size: medium;

        &:hover {
            color: var(--terrakot)
        }
    }

    &__card-header {
        display: flex;
        flex-direction: row;
        align-items: center;

        & p {
            margin-left: 25px;
            max-width: 100px;
            font-size: large;
            color: var(--grafit);
            font-weight: bold;
        }

        & img {
            width: 130px;
            height: 130px;
            border-radius: 50%;
            object-fit: cover;
            object-position: 50% top;
        }
    }
}

.contacts-2cards {
    display: flex;
    flex-direction: row;
    justify-content: center;
    flex-wrap: wrap;
    gap: 30px;

    &__first {
        padding: 0 25px;
        &>img {
            width: 140px;
        }

        &_map {
            color: white;
            padding: 0;
        }
        &_map-text {
            width: 100%;
            backdrop-filter: brightness(0.5);
            padding: 15px 0 0 15px;
        }
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        width: 550px;
        height: 240px;
        background-color: white;

        @media screen and (max-width: 1199px) {
            width: 300px;
        }
    }

    &__second {
        padding: 0 25px;
        &>img {
            width: 140px;
        }
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        width: 550px;
        height: 240px;
        background-color: white;

        @media screen and (max-width: 1199px) {
            width: 300px;
        }

        &_map {
            padding: 0;
        }
    }

    &__links {
        display: flex;
        flex-direction: column;
        justify-content: center
    }
}