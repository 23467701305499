.how-to-cook {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100vw;
    height: 100vh;
    background-color: black;
    overflow: hidden;

    @media screen and (max-width: 1199px) {
        height: 100%;
        overflow: auto;
    }

    &__cards {
        height: 100%;
        display: flex;
        flex-direction: row;
        justify-content: center;
        gap: 0;
        flex-wrap: wrap;
        background-color: black;

        @media screen and (max-width: 1199px) {
            gap: 8px
        }
    }

    &__card {
        position: relative;
        box-sizing: border-box;
        cursor: pointer;
        width: calc(50%);
        height: calc(50%);
        background-color: black;

        @media screen and (min-width: 1200px) {
            & > ::before {
                content: "";
                position: absolute;
                inset: 0;
                border: 3px solid black; /* Жёсткая внутренняя обводка */
                pointer-events: none; /* Чтобы не мешало кликам */
            }
        }

        @media screen and (max-width: 1199px) {
            width: 100%;
            height: 80%;
        }

        & > video {
            height: 100%;
            width: 100%;

            @media screen and (max-width: 1199px) {
                max-height: 100vh;
            }
        }    
    }

    &__button {
        all: unset;
        display: block;
        position: absolute;
        cursor: pointer;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;

        &_title {
            color: white;
            position: absolute;
            font-size: 3rem;
            bottom: 70px;
            left: 20px;
            z-index: 1;
        
            @media screen and (max-width: 5000px) {
                width: 100%;
                text-align: center;
                vertical-align: middle;
                padding: 10px 0;
                top: 50%;
                left: 50%;
                bottom: unset;
                background-color: color-mix(in srgb, var(--grafit) 50%, transparent);
                transform: translate(-50%, -50%);
            }
        }
    }    
}
