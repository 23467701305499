.feed-back-modal {
    @media screen and (min-width: 1200px) {
        left: 50%;
        transform: translate(-50%);
    }
    top: 50%;
    transform: translate(0, -50%);
    position: fixed;
    z-index: 1000;
    width: var(--modal-w);
    background-color: var(--backgroud);
    border-radius: 5px;
    border: solid 1px var(--gray);
    -webkit-box-shadow: 4px 4px 8px 0px rgba(34, 60, 80, 0.2);
    -moz-box-shadow: 4px 4px 8px 0px rgba(34, 60, 80, 0.2);
    box-shadow: 4px 4px 8px 0px rgba(34, 60, 80, 0.2);

    &__content {
        @media screen and (max-width: 1199px) {
            height: 80vh;
            padding: 10px;
            overflow: auto;
        }
    }

    & h3 {
        margin: 5px 0;
        color: var(--grafit);
    }

    &__title {
        &_head {
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            & h2 {
                font-weight: bold;
                color: var(--grafit);
            }

            & i {
                padding: 15px;
            }
        }
    }

    &__dimmer {
        position: fixed;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        backdrop-filter: blur(4px);
        z-index: 999;
    }

    &__select {
        margin: 20px 0px;
        & select {
            margin: 5px 0;
            width: 250px;
            height: 36px;
            font-size: medium;
            border: none;
            border-bottom: solid 1px var(--gray);
        }
        select:focus {
            outline: none;
        }
        & option {
            padding: 10px;
        }
    }

    &__response {
        margin: 20px 0 0 0;
        & input {
            margin: 5px 0;
            width: 250px;
            height: 36px;
            font-size: medium;
            border: none;
            border-bottom: solid 1px var(--gray);
            transition: all ease 0.2s;
        }
        input:focus {
            outline: none;
            border-bottom: solid 1px var(--terrakot);
        }
    }

    &__description {
        margin: 20px 0px;
        & textarea {
            margin: 5px;
            font-family: Creata;
            font-size: medium;
            padding: 5px;
            width: 90%;
            border: none;
            border-bottom: solid 1px var(--gray);
            transition: all ease 0.2s;
        }
        & textarea:focus {
            outline: none;
            border-bottom: solid 1px var(--terrakot);
        }
    }

    &__button {
        display: flex;
        flex-direction: row;
        width: 100%;
        justify-content: flex-end;

        & button {
            padding: 5px 15px;
            border: none;
            border-radius: 10px;
            font-size: medium;
            background-color: var(--terrakot);
            color: var(--white);
            cursor: pointer;
        }

        & button:hover {
            filter: brightness(.9);
        }

        & button:active {
            filter: brightness(.6);
        }

        & button:disabled {
            background-color: var(--checkbox-dark-gray);
            color: var(--gray);
        }
    }
}