.retail-products {
	display: grid;
	position: relative;
	grid-template-columns: repeat(2, 600px);
	grid-auto-rows: 250px;
	margin-top: -40px;
	gap: 40px;

	@media screen and (max-width: 1199px) {
		grid-template-columns: repeat(1, 100vw);
		grid-auto-rows: 250px;
		gap: 0;
	}

	&__header {
		font-size: 36px;

		@media screen and (max-width: 1199px) {
			font-size: 1.8em;
			text-align: center;
		}
	}

	&__burger {
		width: var(--page-w);
		display: grid;
		grid-template-columns: 600px 600px;
		margin-bottom: -30px;

		@media screen and (max-width: 1199px) {
			grid-template-columns: 100vw;
			margin-bottom: 0px;
		}

		& > h1 {
			font-size: 36px;
			color: var(--grafit);
			@media screen and (max-width: 1199px) {
				font-size: 1.8em;
			}
		}

		& > img {
			align-self: center;
			width: 600px;
			@media screen and (max-width: 1199px) {
				width: 100vw;
			}
		}

		& > div {
			width: 100%;
			display: flex;
			flex-direction: column;
			justify-content: center;

			& :nth-child(2) {
				font-size: 22px;
				color: var(--grafit);
				@media screen and (max-width: 1199px) {
					font-size: 1.4em;
				}
			}

			& :nth-child(3) {
				font-size: 18px;
				color: var(--font-gray);
                @media screen and (max-width: 1199px) {
					font-size: 0.8em;
				}
			}

			& > button {
				background-color: var(--grafit);
				color: var(--white);
				width: 250px;
				border-radius: 20px;
				padding: 10px;
				cursor: pointer;

                @media screen and (max-width: 1199px) {
                    border-radius: 0;
                    width: 100vw;
                    border: none;
				}

				&:active {
					filter: brightness(0.6);
				}

				&:hover {
					filter: brightness(0.8);
				}
			}
		}
	}

	&__mockup {
		z-index: 100;
		width: var(--page-w);
		display: grid;
		grid-template-columns: 600px 600px;
		margin-bottom: -100px;

        @media screen and (max-width: 1199px) {
            grid-template-columns: 100vw;
            margin-bottom: -50px;
        }

		& h1 {
			max-width: 500px;
			font-size: 36px;
			color: var(--terrakot);

            @media screen and (max-width: 1199px) {
                margin-top: 30px;
                grid-template-columns: 100vw;
                max-width: 100vw;
                font-size: 1.8em;

            }
		}

		& > img {
			margin-left: 70px;
			align-self: center;
			width: 530px;

            @media screen and (max-width: 1199px) {
                width: 100vw;
                margin-left: 0;
            }
		}

		& > div {
			width: 100%;
			display: flex;
			flex-direction: column;
			justify-content: center;

			& :nth-child(2) {
				font-size: 22px;
				color: var(--grafit);
				max-width: 570px;
			}

			& > button {
				background-color: var(--grafit);
				color: var(--white);
				width: 250px;
				border-radius: 20px;
				padding: 10px;
			}
		}
	}

	&__item {
		z-index: 2;
		height: 100%;
		width: 100%;
		display: grid;
		overflow: hidden;
		grid-template-columns: 250px 350px;
		background-color: white;

		@media screen and (max-width: 1199px) {
			grid-template-columns: 50vw 50vw;
			justify-items: center;
			align-items: center;
		}

		&__photo {
			margin-top: 30px;
			width: 270px;
			background-size: cover;
			background-position: top;

			@media screen and (max-width: 1199px) {
				width: 100%;
				height: 100%;
				margin: 0;
			}
		}

		& h3 {
			margin: 100px 30px 0 30px;
			font-size: 24px;
			color: var(--terrakot);

			@media screen and (max-width: 1199px) {
				margin: 0;
				font-size: 1.2em;
			}
		}
	}

	&__benefits {
		margin-top: 30px !important;
		display: grid;
		grid-template-columns: repeat(2, 1fr);
		grid-auto-rows: minmax(20px, auto);

		@media screen and (max-width: 1199px) {
			grid-template-columns: repeat(1, 1fr);
			grid-auto-rows: minmax(10px, auto);
			margin-top: 10px !important;
		}

		& li {
			margin: 15px;
			font-size: 22px;
			color: var(--grafit);
			width: 400px;

			@media screen and (max-width: 1199px) {
				grid-template-columns: repeat(1, 1fr);
				grid-auto-rows: minmax(10px, auto);
				width: 90vw;
				font-size: 1.2em;
				margin: 7px;
			}

			& img {
				width: 35px;
			}
		}
	}

	&__white-box {
		margin-top: 30px;
		background-color: var(--white);
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
	}

	&__video {
		& video {
			width: var(--page-w);
			height: 675px;

			@media screen and (max-width: 1199px) {
				height: auto;
				margin: 50px 0 25px 0;
			}
		}
	}

	&__fast-and-cook {
		display: grid;
		grid-template-columns: repeat(3, 350px);
		margin: 20px 0;

		@media screen and (max-width: 1199px) {
			grid-template-columns: repeat(1, 1fr);
			grid-auto-rows: minmax(10px, auto);
			width: 90vw;
			font-size: 1.2em;
			margin: 7px;
		}

		&__item {
			position: relative;
			background-color: var(--white);
			display: flex;
			flex-direction: column;
			justify-content: center;
			align-items: center;

			& span {
				text-align: center;
				max-width: 200px;
				font-size: 22px;
				color: var(--grafit);
			}

			& img {
				margin: 20px 0;
				max-width: 200px;
				max-height: 100px;
			}
		}

		&__item:nth-child(1) {
			border-right: solid 1px var(--darker-gray);
			@media screen and (max-width: 1199px) {
				border-right: none;
			}
		}
		&__item:nth-child(2) {
			border-right: solid 1px var(--darker-gray);
			@media screen and (max-width: 1199px) {
				border-right: none;
			}
		}
	}

	&__where-to-buy {
		margin-top: 15px;
		width: 100vw;
		background-color: var(--grafit);
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;

		& h1 {
			color: var(--backgroud);
		}

		&__list {
			display: grid;
			grid-template-columns: repeat(6, 1fr);
			grid-auto-rows: minmax(20px, auto);
			gap: 30px;
			padding-top: 30px;
			padding-bottom: 55px;

            @media screen and (max-width: 1199px) {
				grid-template-columns: repeat(2, 1fr);
			}

			&__item {
				& span {
					margin-left: 40px;
					color: var(--terrakot);
				}
			}

			& .slice-list__item {
				color: var(--backgroud);
				font-weight: 100;
			}
		}
	}
}
