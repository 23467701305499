.career-links {

    &__container {
        margin-top: 40px;
        background-color: white;
        padding: 20px;
    }

    margin-top: 40px;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-auto-rows: minmax(20px, auto);
    gap: 10px 100px;
    margin-bottom: 20px;

    @media screen and (max-width: 700px) {
        grid-template-columns: repeat(2, 1fr);
        gap: 10px 10px;
    }

    & div {
        cursor: pointer;
        font-size: 14px;
        color: var(--grafit);
        text-decoration: underline solid;

        &:hover {
            font-weight: bold;
            color: var(--terrakot);
        }
    }
}