.reconstruction {
    width: 100vw;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #CFCECA;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: top left;

    @media screen and (max-width: 1199px) {
        background-size: contain;
        background-repeat: no-repeat;
        background-position: center center;
    }

    & > div {
        background-color: color-mix(in srgb, var(--new-brown) 50%, transparent);
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        width: 100%;
        padding: 10px 10px;

        & > h3 {
            font-size: calc(1rem + 2vw);
            color: white;
            text-align: center;
        
            @media screen and (max-width: 1199px) {
                font-size: calc(1rem + 1vw);
            }
        }
    
        & > h1, a {
            font-size: calc(2rem + 2vw);
            color: white;
            text-align: center;
    
            &:hover {
                color: var(--terrakot)
            }

            @media screen and (max-width: 1199px) {
                font-size: calc(1.5rem + 1.5vw);
            }
        }
    }
}
