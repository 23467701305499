.slice-list {
    list-style-type: none;
    margin: 0;
    padding: 0;

    &__item {
        color: var(--font-gray);
        display: flex;
        flex-direction: row;
        align-items: center;
        gap: 15px;
        margin-top: 10px;

        & img {
            width: 25px;
        }
    }
}