.story-slider {
    // margin-top: 30px;
    width: var(--l-width);
    transition: all ease 0.3s;
    position: relative;
    max-height: 620px;

    @media screen and (max-width: 1199px) {
        overflow-x: auto;
    }

    &__content {
        animation: fadeIn .6s forwards;
        animation-timing-function: ease-in-out;
        border-radius: 20px;
        margin-top: 180px;
        padding: 25px;
        background-color: white;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        -webkit-box-shadow: 4px 4px 8px 0px rgba(34, 60, 80, 0.2);
        -moz-box-shadow: 4px 4px 8px 0px rgba(34, 60, 80, 0.2);
        box-shadow: 4px 4px 8px 0px rgba(34, 60, 80, 0.2);

        @media screen and (max-width: 1199px) {
            margin-top: 15px;
            width: auto;
            border-radius: 0;
        }

        & p {
            color: var(--grafit);
            & span {
                color: var(--terrakot);
            }
            width: 600px;
            @media screen and (max-width: 1199px) {
                width: 80%;
            }
        }
        & .video {
            margin-top: -5px;
            width: 600px;
            height: auto;
            @media screen and (max-width: 1199px) {
                width: 100vw;
            }
        }
        & video {
            @media screen and (max-width: 1199px) {
                width: 100vw;
            }
            height: auto;
            width: 600px;
        }
    }

    &__slides {
        transition: all ease 0.3s;
        position: absolute;
        @media screen and (max-width: 1199px) {
            position: relative;
        }
        display: grid;
        grid-auto-flow:column;
        // grid-gap: 20px;
        scroll-behavior: smooth;
        background: transparent;

        & .story-slider__item:nth-child(2n) {
            & .story-slider__item__img {
                transform: rotate(180deg);
            }

            & .story-slider__item__text {
                top: 40%
            }
        }
        // & .story-slider__item:nth-child(3n+1) {
        //     & .story-slider__item__img {
        //         transform: rotate(10deg);
        //     }
        // }
        // & .story-slider__item:nth-child(5n+1) {
        //     & .story-slider__item__img {
        //         transform: rotate(15deg);
        //     }
        // }

        & .story-slider__divider:not(:first-child) {
            position: relative;
            z-index: 0;
            width: 100px;
            height: 50px;
            margin: 10px -60px;
            background: transparent;
            border: dashed 2px gray;
            border-top: 0px;
            border-radius: 0 0 70px 70px ;
            transform: rotate(-10deg);

            &::after {
                position: absolute;
                top: 0;
                content: '';
                z-index: 10;
                background-color: #fcfcfc;
                margin: 0 -10px;
                height: 30px;
                width: 120px;
            }
        }

        & .story-slider__divider:nth-child(3n+1) {
            transform: rotate(170deg);
            margin: 30px -50px;
        }

                & .story-slider__divider:nth-child(3n+1) {
            transform: rotate(170deg);
            margin: 30px -50px;
        }
    }

    &__item {
        margin: 0px -15px;
        cursor: pointer;
        z-index: 2;
        position: relative;
        height: 170px;
        width: 170px;

        &__img {
            position: relative;
            width: 170px;
            transition: ease all 0.5s;
            & img {
                width: 170px;
            }
        }

        &__text {
            width: 100px;
            text-align: center;
            position: absolute;
            left: 50%;
            top: 60%;
            transform: translate(-50%, -50%);
            color: white;
        }

    }

    &__buttons {
        top: -35px;
        right: 0;
        width: 100px;
        position: absolute;
        display: flex;
        flex-direction: row;
        margin-right: 20px;
        z-index: 51;

        & :first-child {
            border-top-left-radius: 20px;
            border-bottom-left-radius: 20px;
            border-top-right-radius: 0;
            border-bottom-right-radius: 0;
        }

        & div {
            background-color: red;
            height: 40px;
            width: 50px;
            color: var(--grafit);
            border: solid 1px var(--grafit);
            display: flex;
            flex-direction: column;
            border-top-right-radius: 20px;
            border-bottom-right-radius: 20px;
            backdrop-filter: blur(4px);
            background-color: rgba(256, 256, 256, .2);
            cursor: pointer;

            & span {
                font-size: 40px;
                line-height: 40px;
                text-align: center;
            }

            &:hover {
                color: var(--white);
                background-color: var(--grafit);
            }
        }
    }
}
