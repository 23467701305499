.easy-slider {
    margin-top: 110px;
    width: var(--l-width);
    transition: all ease 0.3s;
    position: relative;
    height: 300px;
    margin-bottom: 70px;

    & h1 {
        margin-top: 0px;
        margin-bottom: 25px;

    }

    &__slides {
        width: var(--l-width);
        transition: all ease 0.3s;
        position: absolute;
        display: grid;
        grid-auto-flow:column;
        grid-gap: 20px;
        scroll-behavior: smooth;
        background: transparent;
        // scroll-behavior: smooth;
        overflow-y: hidden;
        overflow-x: scroll;
        -ms-overflow-style: none;

        &::-webkit-scrollbar { width: 0; }
    }

    &__item {
        cursor: pointer;
        z-index: 2;
        position: relative;
        height: 270px;

        & img {
            height: 270px;
        }

        & span {
            position: absolute;
            left: 50%;
            top: 50%;
            transform: translate(-50%, -50%);
            color: white;
        }

    }

    &__buttons {
        top: -5px;
        right: 0;
        width: 100px;
        position: absolute;
        display: flex;
        flex-direction: row;
        margin-right: 20px;
        z-index: 51;

        & :first-child {
            border-top-left-radius: 20px;
            border-bottom-left-radius: 20px;
            border-top-right-radius: 0;
            border-bottom-right-radius: 0;
        }

        & div {
            background-color: red;
            height: 40px;
            width: 50px;
            color: var(--grafit);
            border: solid 1px var(--grafit);
            display: flex;
            flex-direction: column;
            border-top-right-radius: 20px;
            border-bottom-right-radius: 20px;
            backdrop-filter: blur(4px);
            background-color: rgba(256, 256, 256, .2);
            cursor: pointer;

            & span {
                font-size: 40px;
                line-height: 40px;
                text-align: center;
            }

            &:hover {
                color: var(--white);
                background-color: var(--grafit);
            }
        }
    }
}
