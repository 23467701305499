@use './c.scss';
.reciepes {
    @media screen and (min-width: 1200px) {
        margin-right: -10px;
        margin-top: 15px;
    }

    width: var(--page-w);
    display: flex;
    flex-flow: row wrap;
    align-content: space-between;
    max-height: 800px;
    overflow-y: scroll;
    font-size: 20px;

    &_thin {
        max-height: 100%;
        align-content: flex-start;
    }

    &_thin .reciepe-card {
        height: 300px;
        width: 300px;
    }

    @media screen and (max-width: 1199px) {
        margin-top: 5px;
        display: grid;
        gap: 5px;
        grid-template-columns: 50vw 50vw;
        .reciepe-card {
            width: auto !important;
            height: 30vh;
            padding: 0;
            margin: 0;
            font-size: 0.8rem;
        }
    }

    &-header {
        display: flex;
        flex-direction: row;
        gap: 10px;
        align-items: center;
        margin: 45px 0 15px 0;

        @media screen and (max-width: 1199px) {
            margin: 20px 0 5px 0;
        }

        & h2 {
            color: var(--grafit);
            font-size: var(--text-size-36);

            @media screen and (max-width: 1199px) {
                font-size: 1.4em;
            }
        }

        & img {
            height: 130px;
        }
    }
}
.reciepes::-webkit-scrollbar {
    display: none;
}

.reciepe-card {
    width: 100%;
    position: relative;
    margin: 10px;
    height: 380px;
    box-sizing: border-box;
    background-size: cover;
    background-position: center;
    box-shadow: inset 0 -150px 150px -100px #000000;
    transition: all ease 0.3s;
    cursor: pointer;

    &__new {
        position: absolute;
        right: 10px;
        top: 10px;
        padding: 3px 5px 8px 5px;
        text-align: center;
        background-color: var(--terrakot);
        color: white;
        border-radius: 15px;
    }

    &:hover {
        box-shadow: inset 0 -200px 150px -100px #000000;
    }

    & i {
       position: absolute;
       right: 0;
       top: 0;
    }
}
.reciepe-card>h3{
    position: absolute;
    bottom: 15px;
    left: 20px;
    color: white;
}
