.video {
	margin-top: 80px;
	position: relative;
	background-color: black;
	background-image: cover;
	margin-bottom: 20px;

	@media screen and (max-width: 1199px) {
		margin: 0;
		padding: 10px 0 5px 0;
		background-color: var(--white);
	}

	& video {
		width: 100vw;
		@media screen and (min-width: 1200px) {
			height: 500px;
		}
	}

	// & video::-webkit-media-controls-play-button {
	// 	display: none;
	// }

	& .video-overlay {
		position: absolute;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
		pointer-events: none;

		&__share {
			pointer-events: all;
			position: absolute;
			top: 0;
			right: 0;
			margin: 15px;

			& i {
				&:hover {
					color: var(--terrakot);
				}
			}
		}
	}

	& .video-controls {
		position: absolute;
		left: 0;
		right: 0;
		bottom: 60px;
		top: 0;
		display: flex;
		justify-content: center;
		align-items: center;

		&__button {
			color: var(--yellow);
			margin-top: 20px;
			font-size: 50px;
			border-top-right-radius: 50px;
			border-bottom-right-radius: 50px;
			line-height: 100px;
			height: 100px;
			width: 100px;
			text-align: center;
			background-color: var(--terrakot);
			transition: all ease 0.5s;
			opacity: 0;
			cursor: default;

			&:hover {
				color: var(--white);
				box-shadow: 0 0 0 15px rgba($color: white, $alpha: 0.1),
					0 0 0 30px rgba($color: white, $alpha: 0.2),
					0 0 0 45px rgba($color: white, $alpha: 0.4);
			}

			&:active {
				filter: brightness(80%);
			}

			&_active {
				cursor: pointer;
				opacity: 1;
			}
		}
	}
}

.career-videos {
	width: 1380px;
	display: grid;
	grid-template-columns: repeat(3, 1fr);
	grid-auto-rows: 200px;
	margin-top: 20px;

	& .career-video {
		background-color: white;
		margin: 10px;
	}

	& :first-child {
		grid-column-start: 1;
		grid-row-start: 1;
		grid-row-end: 3;
	}

	& video {
		border-radius: 10px;
		border: solid var(--gray) 1px;
		height: 100%;
		width: auto;
		max-width: 712px;
	}

	& .video-controls__button {
		margin-top: 50px;
		font-size: 25px;
		border-top-right-radius: 25px;
		border-bottom-right-radius: 25px;
		line-height: 50px;
		height: 50px;
		width: 50px;

		&:hover {
			color: var(--terrakot);
			box-shadow: 0 0 0 7px rgba($color: white, $alpha: 0.1),
				0 0 0 15px rgba($color: white, $alpha: 0.2),
				0 0 0 30px rgba($color: white, $alpha: 0.4);
		}
	}
}
