.about-cards {
    margin-top: 10px;
    width: var(--page-w);
    display: grid;
    grid-template-columns: 400px 400px 400px;

    @media screen and (max-width: 1199px) {
        grid-template-columns: 100vw;
    }

    &__card {
        padding: 15px;
        max-width: 100%;

        & p {
            color: var(--grafit);
        }
    }

    &__img {
        background-size: cover;
        background-position: center;
        height: 260px;
    }

}

.about-slice-cards {
    width: 1050px;
    display: grid;
    grid-template-columns: 350px 350px 350px;
    margin: -20px 0 -60px 0;

    @media screen and (max-width: 1199px) {
        display: flex;
        flex-direction: column;
        width: 100vw;
        margin: 20px 0 -20px 0;
        align-items: center;
        align-content: center;
    }

    &__card {
        position: relative;
        max-width: 100%;

        @media screen and (max-width: 1199px) {
            width: 400px;

            & p {
                font-size: 1em;
            }
        }

        &:first-child {
            & p {
                top: 55%
            }
        }
        &:nth-child(2) {
            & p {
                margin-top: -30px;
                @media screen and (max-width: 1199px) {
                    margin-top: 15px
                }
            }
            & .about-slice-cards__img {
                transform: rotate(180deg);
                @media screen and (max-width: 1199px) {
                    transform: rotate(0deg);
                }
            }
        }

        & p {
            text-align: center;
            z-index: 10;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            position: absolute;
            width: 210px;
            color: white;
        }
    }

    &__img {
        background-size: cover;
        background-position: center;
        height: 400px;
        @media screen and (max-width: 1199px) {
            height: 250px;
        }
    }

}

.about-list {
    margin: 0;
    padding: 0;
    width: var(--630-w);
    margin-bottom: 25px;

    & li {
        margin-top: 25px;
        list-style-type: none;
        display: flex;
        flex-direction: row;
        align-items: center;
        color: var(--grafit);
        @media screen and (max-width: 1199px) {
            padding-left: 5px;
            font-size: 1em;
        }

        & img {
            height: 1.3em;
            margin-right: 20px;
        }
    }
}